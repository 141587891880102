const Images = {
  portfolio: {
    portfolio00: require("./portfolio00.jpg"),
    portfolio01: require("./portfolio01.jpg"),
    portfolio02: require("./portfolio02.jpg"),
    portfolio03: require("./portfolio03.jpg"),
    portfolio04: require("./portfolio04.jpg"),
  },
  profile: {
    profile00: require("./profile00.jpg"),
    profile01: require("./profile01.jpg"),
  },
  services: "./services.jpg",
}

export default Images
